export default {
  props: {
    num: {
      type: Number,
      required: true
    },
    options: {
      type: Object,
      default: () => {
        return {
          duration: 0.6,
          // 动画时间
          transitionTiming: "ease",
          digit: 0 // 默认显示几位数
        };
      }
    }
  },
  data() {
    return {
      ttClass: {}
    };
  },
  computed: {
    valLen() {
      return this.options.digit || (this.num + "").length;
    },
    numStyle() {
      return {};
    }
  },
  watch: {
    num() {
      this.scroNum();
    }
  },
  mounted() {
    this.scroNum();
  },
  methods: {
    scroNum() {
      var number = this.num;
      var $num_item = this.$refs.num_items;
      var h = $num_item[0].offsetHeight;
      // 组装 动画样式
      this.ttClass = {
        transition: `all ${this.options.duration + "s"} ${this.options.transitionTiming}`
      };
      var numberStr = number.toString();
      if (numberStr.length <= $num_item.length - 1) {
        var tempStr = "";
        for (var a = 0; a < $num_item.length - numberStr.length; a++) {
          tempStr += "0";
        }
        numberStr = tempStr + numberStr;
      }
      var numberArr = numberStr.split("");
      console.log("valLen", this.valLen);
      $num_item.forEach(function (item, i) {
        setTimeout(function () {
          const top = -parseInt(numberArr[i]) * h + "px";
          item.style.top = top;
        }, i * 10); // 每个牌子 转动间隔时间
      });
    }
  }
};